.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 50%;
    z-index: 2147483001;
    background-image:  url("/storage/imgs/logos/zp-logo-preloader-orange.png");
    background-position:  bottom center;
    background-repeat:no-repeat;
    background-color: #fff;
}

.se-pre-con2 {
    position: fixed;
    left: 0px;
    top: 50%;
    width: 100%;
    height: 50%;
    z-index: 2147483001;
    background: url("/storage/imgs/logos/preloader-orange.gif") top center no-repeat #fff;
}
